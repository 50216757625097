import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

declare const $: any;
declare const swal: any;

@Component({
    selector: 'app-menu-initiatives-details',
    templateUrl: './menu-initiatives-details.component.html',
    styleUrls: ['../../app.component.css', './menu-initiatives-details.component.css']
})

export class MenuInitiativesDetailsComponent implements OnInit {
    
    @Input() iniciativa;

    public loggedUser;

    constructor(
        public router: Router
    ) {

    }
    
    ngOnInit() {
        this.loggedUser = JSON.parse( sessionStorage.getItem('loggedUser') );
        if (this.loggedUser) this.loggedUser = this.loggedUser.data;
        $(function () {
           $('[data-toggle="tooltip"]').tooltip()
        })
    }

    public support() {
        if (this.loggedUser) {
            this.router.navigate(['/initiatives/support/' + this.iniciativa.id]);
        }
        else {
            swal("Usuário não Logado!","Para executar esta ação você precisa estar Logado!","warning");
        }
    }
}
