import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable()
export class IniciativaService {

    public appURL= environment.apiUrl + "/api/v2/";
    
    constructor(
        public http: HttpClient,
    ) {

    }

    get options() {
        let options = {
            headers:{
                'Content-Type':  'application/json',
            }
        };
        if (sessionStorage) {
            let token = sessionStorage.getItem('token');
            if (token) {
                options.headers["Authorization"] = token;
            }
        }
        return options;
    }

    public delete(url) {

        let client_id = sessionStorage.getItem("client_id");
        if (client_id) {
            if (url.indexOf("?") < 0) url += "?"; else url += "&";
            url += "client_id=" + client_id;
        }

        return this.http.delete(this.appURL + url,this.options);
    }

    public get(url) {

        let client_id = sessionStorage.getItem("client_id");
        if (client_id) {
            if (url.indexOf("?") < 0) url += "?"; else url += "&";
            url += "client_id=" + client_id;
        }

        return this.http.get(this.appURL + url,this.options);
    }

    public post(url,data) {

        let client_id = sessionStorage.getItem("client_id");
        if (client_id) {
            if (url.indexOf("?") < 0) url += "?"; else url += "&";
            url += "client_id=" + client_id;
        }

        return this.http.post(this.appURL + url,data,this.options);
    }

    public iniciativas(params) {
        let qry = "";
        
        if (params.usuario) {
            return this.get("client/iniciativa/usuario/" + params.usuario);
        }
        
        if (params.tipo) {
            if (qry == "") qry = "?"; else qry += "&";
            qry += "tipo=" + params.tipo;
        }

        if (params.user_id) {
            if (qry == "") qry = "?"; else qry += "&";
            qry += "user_id=" + params.user_id;
        }
        
        if (params.count) {
            if (qry == "") qry = "?"; else qry += "&";
            qry += "count=" + params.count;
        }
        
        return this.get("client/iniciativa" + qry);
    }

    public registrarApoio(user_id,apoios) {
        let data = {
            user_id: user_id,
            apoios: apoios 
        }
        return this.post("client/iniciativa/apoiadores",data);
    }

    public detalhesApoios(iniciativa) {
        let user = JSON.parse( sessionStorage.getItem("loggedUser") );
        return this.get("client/iniciativa/apoios/detalhado/" + iniciativa);
    }

    public meusApoios() {
        let user = JSON.parse( sessionStorage.getItem("loggedUser") );
        return this.get("client/iniciativa/apoios/meus/" + user.data.id);
    }

    public outrosApoios() {
        let user = JSON.parse( sessionStorage.getItem("loggedUser") );
        return this.get("client/iniciativa/apoios/outros/" + user.data.id);
    }

    public semApoio() {
        return this.get("client/iniciativa/apoios/nenhum");
    }

    public dadosDoApoio(apoio_id,user_id) {
        return this.get("client/iniciativa/apoio/" + apoio_id + "/" + user_id);
    }

    public resumo() {
        let user = JSON.parse( sessionStorage.getItem("loggedUser") );
        return this.get("client/iniciativa/resumo/" + user.data.id);  
    }

}
