import { Component, OnInit } from '@angular/core';

declare const $:any;

@Component({
  selector: 'app-my-page-sidebar',
  templateUrl: './my-page-sidebar.component.html',
  styleUrls: ['../../app.component.css', 'my-page-sidebar.component.css']
})

export class MyPageSidebarComponent implements OnInit {

	 ngOnInit () {
		 	$(document).ready(function () {
		    $('#sidebarCollapse').on('click', function () {
		        $('.sidebar, .content').toggleClass('active');
		    });
		});
	 }

}
