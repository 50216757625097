import {Injectable, OnInit} from '@angular/core';
import {Router, CanActivate} from '@angular/router';
import {AuthService} from './auth.service';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/first';
import 'rxjs/add/operator/catch';
import {Observable} from 'rxjs/Observable';

declare var swal: any;

@Injectable()
export class AuthGuardService implements CanActivate, OnInit {

  constructor(public auth: AuthService, public router: Router) {
  }

  ngOnInit(): void {
    console.log('iniciei');
  }

  canActivate(): Observable<boolean> {
    const clientId = sessionStorage.getItem('client');
    return this.auth.verifyClient(+clientId)
      .map((res:any) => {
        if (res.success) {
          return true;
        } else {
          this.router.navigate(['/erros/403']);
        }
      }).first();
  }

  errorNotificate(msg: string): any {
    swal({
      title: 'Erro',
      text: msg,
      type: 'warning',
      confirmButtonClass: 'btn btn-success',
    });
  }
}
