import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './loja/base.service';


@Injectable()
export class PlanService extends BaseService {

    constructor(
        protected http: HttpClient
    ) {
        super(http, 'api/v2', 'planos/list')
    }

    public userInPlan(plan_id) {
        this.entityName = "planos";
        let user_id = JSON.parse( sessionStorage.getItem("loggedUser") ).data.id;
        let result = this.getResources({router:"user/" + user_id + "/" + plan_id});
        this.entityName = "planos/list";
        return result;
    }
    
    public addUserToPlan(plan_id) {
        this.entityName = "planos";
        let user_id = JSON.parse( sessionStorage.getItem("loggedUser") ).data.id;
        let result = this.updateResource({},{router:"user/" + user_id + "/" + plan_id});
        this.entityName = "planos/list";
        return result;
    }

    public delUserToPlan(plan_id) {
        this.entityName = "planos";
        let user_id = JSON.parse( sessionStorage.getItem("loggedUser") ).data.id;
        let result = this.deleteResource({router:"user/" + user_id + "/" + plan_id});
        this.entityName = "planos/list";
        return result;
    }
    

}