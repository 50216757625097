import { Component} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { DateAdapter } from '@angular/material/core';

declare var $:any;

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    constructor(
        public  router : Router,
        private adapter: DateAdapter<any>
    ) {
        this.adapter.setLocale("pt-BR");
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            $("html, body, window").animate({scrollTop:0}, '1000');
        });
    }


}
