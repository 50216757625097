import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { environment } from '../../../environments/environment';


@Injectable()
export class loginSocial {

  constructor(public http: HttpClient) {

  }

  save(person: any): Observable<any> {
    console.log("service", person);
    return this.http.post(environment.baseUrl + '/api/v2/client/cad', person);
  }

  getHeaders(): {headers: HttpHeaders} {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Accept': 'application/json'
    };
    return {headers: new HttpHeaders(headerOptions)};
  }

}
