import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { Global } from 'asap-crud';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['../../app.component.css', './navbar.component.css']
})

export class NavbarComponent implements OnInit{

  public navStyle
  public logo
  public logado

  constructor(
    public router: Router,
    public global: Global,
  ) {
    router.events.subscribe((val) => {

      if (this.router.url.indexOf('/home') > -1) {
        this.navStyle = 'nav-home'
        this.logo = 'logo.png'
      }

      else if (this.router.url.indexOf('/initiatives') > -1) {
        this.navStyle = 'nav-initiatives'
        this.logo = 'logo-white.png'
      }

      else if (this.router.url.indexOf('/development') > -1) {
        this.navStyle = 'nav-development'
        this.logo = 'logo-white.png'
      }

      else if (this.router.url.indexOf('/forum') > -1) {
        this.navStyle = 'nav-forum'
        this.logo = 'logo-white.png'
      }

      else {
        this.navStyle = 'nav-home'
        this.logo = 'logo.png'
      }

    });
  }

  ngOnInit(): void {
    this.loggedVerify();
  }

  loggedVerify(){

  }

  logoff() {
    sessionStorage.removeItem("loggedUser");
    sessionStorage.removeItem("token");
    this.router.navigate(["/register"]);
  }

}
