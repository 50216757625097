/* ==== Imports Módulos === */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';

/* ==== Imports Components === */
import { UploadPluginComponent } from './upload.component'
import { ModalUploadPluginComponent } from './modal-upload/modal-upload.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSnackBarModule,
    ImageCropperModule
  ],
  declarations: [
    UploadPluginComponent,
    ModalUploadPluginComponent
  ],
  exports: [
    UploadPluginComponent,
    ModalUploadPluginComponent
  ],
  entryComponents: [ 
    ModalUploadPluginComponent
  ]
})

export class UploadModule { }
