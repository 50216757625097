import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'angular2-moment';
import { UploadModule } from './upload/upload.module';
import { SpinnerModule } from '../modules/shared/spinner/spinner.module';
import { MatTooltipModule } from '@angular/material/tooltip';

import { NavbarComponent } from './navbar/navbar.component';
import { SideMenuComponent } from './sidemenu/sidemenu.component';
import { MenuHomeComponent } from './menu-home/menu-home.component';
import { MenuInitiativesComponent } from './menu-initiatives/menu-initiatives.component';
import { MenuInitiativesDetailsComponent } from './menu-initiatives-details/menu-initiatives-details.component';
import { MenuPanelComponent } from './menu-panel/menu-panel.component';
import { AuthNavbarComponent } from './auth-navbar/auth-navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ModalMembersComponent } from './modal-members/modal-members.component';
import { MyPageSidebarComponent } from './my-page-sidebar/my-page-sidebar.component';
import { PointsComponent } from './points/points.component';
import { GainComponent } from './gain/gain.component';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';
import { createTranslateLoader } from 'app/translate/translate.module';
import { HttpClient } from '@angular/common/http';

@NgModule({
  imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      ReactiveFormsModule,
      MomentModule,
      UploadModule,
      SpinnerModule,
      MatTooltipModule,
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: (createTranslateLoader),
            deps: [HttpClient]
        },
        isolate: false
    })
  ],
  declarations: [
      NavbarComponent,
      SideMenuComponent,
      MenuHomeComponent,
      MenuInitiativesComponent,
      MenuInitiativesDetailsComponent,
      MenuPanelComponent,
      AuthNavbarComponent,
      ModalMembersComponent,
      MyPageSidebarComponent,
      FooterComponent,
      PointsComponent,
      GainComponent
  ],
  providers: [
      TranslateService
  ],
  exports: [
      TranslateModule,
      NavbarComponent,
      SideMenuComponent,
      MenuHomeComponent,
      MenuInitiativesComponent,
      MenuInitiativesDetailsComponent,
      MenuPanelComponent,
      AuthNavbarComponent,
      ModalMembersComponent,
      MyPageSidebarComponent,
      FooterComponent,
      PointsComponent,
      GainComponent,
      UploadModule,
      SpinnerModule,
      MatTooltipModule
  ]
})
export class SharedModule {
    constructor(translate: TranslateService) {
        translate.setDefaultLang('pt-br');
        translate.use('pt-br');
    }
}
