import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-members',
  templateUrl: './modal-members.component.html',
  styleUrls: ['../../app.component.css', '../menu-home/menu-home.component.css']
})

export class ModalMembersComponent {

}
