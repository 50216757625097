import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';


export class BaseService {
  constructor(
    protected http: HttpClient,
    protected basePath: string,
    protected entityName: string) {}

  protected host: string = environment.apiUrl
  protected token = sessionStorage.getItem('token')
  protected headers = {
    'Content-Type': 'application/json',
    'Authorization': this.token,
    'Access-Control-Allow-Origin': '*'
  };

  protected options = {
    headers: this.headers
  };

  getResources(params = {}) {
    return this.http.get(this.resourceUrl(null, params), this.options);
  }

  getResource(id, params = {}) {
    return this.http.get(this.resourceUrl(id, params), this.options);
  }

  createResource(data, params = {}) {
    return this.http.post(this.resourceUrl(null, params), data, this.options);
  }
  
  updateResource(data, params = {}) {
    return this.http.put(this.resourceUrl(data.id, params), data, this.options);
  }

  deleteResource(id) {
    return this.http.delete(this.resourceUrl(id), this.options);
  }

  resourceUrl(id = null, params = {}) {

    const endpoint = [
      this.host,
      this.basePath,
      this.urlParameters(params),
      this.entityName,
      id
    ].filter(element => element != false).join('/').replace(/\/$/, '');

    let url = endpoint + this.queryString(params);

    let client_id:any = sessionStorage.getItem("client_id");
    if (client_id) {
      if (url.indexOf("?") >= 0) url = url + "&"; else url = url + "?";
      url = url + "client_id=" + client_id;
    }

    return url;
  }
  
  protected urlParameters(params) {
    var urlParameters = []

    for(var placeholder in params)
      if (/.*_id$/.test(placeholder))
        urlParameters.push(`${placeholder}/${params[placeholder]}`)

    return urlParameters.join('/')
  }

  protected queryString(params) {
    if(params.query){
      return `?${params.query}`
    }else if(params.router){
      return `/${params.router}`    
    }else {
      return ''
    }
  }


}
