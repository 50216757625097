import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { BaseService } from './loja/base.service';


@Injectable()
export class EventService extends BaseService {

    constructor(
        public http:HttpClient
    ) {
        super(http,'api/v2/client','event');
    }

    public initiatives(event_id) {
        return this.getResources({router:'initiatives/' + event_id});
    } 

    public events(user_id,confirmed=null) {
        let qry="";

        if (confirmed != null) {
            qry = "/" + confirmed;
        }

        return this.getResources({router:'user/list/' + user_id + qry});
    }

    public subscribe(id,data) {
        return this.createResource(data,{router:'subscribe/' + id});
    }

    public addInitiative(event_id,initiative_id) {
        return this.getResources({router:'initiative/add/' + event_id + '/' + initiative_id});
    }

    public delInitiative(event_id,initiative_id) {
        return this.getResources({router:'initiative/del/' + event_id + '/' + initiative_id});
    }

    public confirmInitiative(event_id,user_id,confirmed) {
        return this.getResources({router:'initiative/confirm/' + event_id + '/' + user_id + '/' + confirmed});
    }

    public subscribedInitiatives(event_id) {
        return this.getResources({router:'subscribed/initiatives/' + event_id});
    } 

    public users(event_id) {
        return this.getResources({router:'users/' + event_id});
    } 

    public addUser(event_id,user_id) {
        return this.getResources({router:'user/add/' + event_id + '/' + user_id});
    }

    public delUser(event_id,user_id) {
        return this.getResources({router:'user/del/' + event_id + '/' + user_id});
    }

    public confirmUser(event_id,user_id,confirmed) {
        return this.getResources({router:'user/confirm/' + event_id + '/' + user_id + '/' + confirmed});
    }

    public subscribedUsers(event_id) {
        return this.getResources({router:'subscribed/users/' + event_id});
    } 

    public eventsByOwner(user_id) {
        return this.getResources({router:'owner/list/' + user_id});
    } 

    public supports(event_id) {
        return this.getResources({router:'supports/' + event_id});
    } 
    
}
