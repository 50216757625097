import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

@Injectable()
export class ClientInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const clientId = sessionStorage.getItem('client');
      // const changeReq = req.clone({
      //   params: req.params.append('client_id', clientId)
      // });

    const changeReq = (req as HttpRequest<any>).clone({ params: req.params.append('client_id', clientId)})

    return next.handle(changeReq);
  }
}
