import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

import { BaseService } from './base.service';

@Injectable()
export class TrailService extends BaseService {

  public fetchAll(): Observable<any> {
    const header = {headers: this.getHeaders()};
    return this.http.get(this.baseUrl + '/api/v2/admin/trail/folder', header);
  }

  public insert(name: string): Observable<any> {
    const url = this.baseUrl + '/api/v2/admin/trail/folder/post';
    const body = {
      nome_pasta: name
    };
    const header = {headers: this.getHeaders()};

    return this.http.post(url, body, header);
  }

  public update(id: number, name: string): Observable<any> {
    const url = this.baseUrl + '/api/v2/admin/trail/folder/' + id + '/update';
    const body = {
      nome_pasta: name
    };
    const header = {headers: this.getHeaders()};

    return this.http.put(url, body, header);
  }

  public deleteFolder(id: number) {
    const url = this.baseUrl + '/api/v2/admin/trail/folder/' + id + '/delete';
    const header = {headers: this.getHeaders()};

    return this.http.delete(url, header);
  }

  public findFolder(id: number): Observable<any> {
    const header = {headers: this.getHeaders()};
    const url = this.baseUrl + '/api/v2/admin/trail/folder/' + id;
    return this.http.get(url, header);
  }

  public folderShared(): Observable<any> {
    const header = {headers: this.getHeaders()};
    const url = this.baseUrl + '/api/v2/admin/shared/folder/user';
    return this.http.get(url, header);
  }

  public folderShop(): Observable<any> {
    const header = {headers: this.getHeaders()};
    const url = this.baseUrl + '/api/v2/admin/shop/curso';
    return this.http.get(url, header);
  }

  public fetchAllItems(): Observable<any> {
    const header = {headers: this.getHeaders()};
    const url = this.baseUrl + '/api/v2/admin/trail';
    return this.http.get(url, header);
  }

  public fetchItems(id: number): Observable<any> {
    const header = {headers: this.getHeaders()};
    const url = this.baseUrl + '/api/v2/admin/trail/folder/' + id + '/items';
    return this.http.get(url, header);
  }

  public allCourses(): Observable<any> {
    const header = {headers: this.getHeaders()};
    const url = this.baseUrl + '/api/v2/loja/cursos';
    return this.http.get(url, header);
  }

  public insertItems(trailId: number, title: string, description: string, image: string): Observable<any> {
    const url = this.baseUrl + '/api/v2/admin/trail/item/post';
    const body = {
       pasta_id: trailId,
       titulo: title,
       descricao: description,
       arquivo: image
    };
    const header = {headers: this.getHeaders()};

    return this.http.post(url, body, header);
  }

  public deleteItem(id: number) {
    const url = this.baseUrl + '/api/v2/admin/trail/item/' + id + '/delete';
    const header = {headers: this.getHeaders()};

    return this.http.delete(url, header);
  }


  public fetchStages(id: number): Observable<any> {
    const header = {headers: this.getHeaders()};
    const url = this.baseUrl + '/api/v2/admin/trail/stage/' + id;
    return this.http.get(url, header);
  }

  public insertStage(itemId: number, title: string, image: string): Observable<any> {
    const url = this.baseUrl + '/api/v2/admin/trail/stage/post';
    const body = {
       item_id: itemId,
       nome: title,
       image: image
    };
    const header = {headers: this.getHeaders()};

    return this.http.post(url, body, header);
  }

  public updateStage(itemId: number, id: number, title: string, image:string) {
    const url = this.baseUrl + '/api/v2/admin/trail/stage/' + id + '/update';
    const body = {
       item_id: itemId,
       nome: title,
       image: image
    };
    const header = {headers: this.getHeaders()};

    return this.http.put(url, body, header);
  }

  public deleteStage(id: number) {
    const url = this.baseUrl + '/api/v2/admin/trail/stage/' + id + '/delete';
    const header = {headers: this.getHeaders()};

    return this.http.delete(url, header);
  }

  public insertSubStage(stageId: number, title: string): Observable<any> {
    const url = this.baseUrl + '/api/v2/admin/trail/sub-stage/store';
    const body = {
       etapa_id: stageId,
       nome: title
    };
    const header = {headers: this.getHeaders()};

    return this.http.post(url, body, header);
  }

  public updateSubStage(stageId: number, id: number, title: string): Observable<any> {
    const url = this.baseUrl + '/api/v2/admin/trail/sub-stage/' + id + '/update';
    const body = {
       etapa_id: stageId,
       nome: title
    };
    const header = {headers: this.getHeaders()};

    return this.http.put(url, body, header);
  }

  public deleteSubStage(id: number) {
    const url = this.baseUrl + '/api/v2/admin/trail/sub-stage/' + id + '/delete';
    const header = {headers: this.getHeaders()};

    return this.http.delete(url, header);
  }

  public getDetail(id) {
    const header = {headers: this.getHeaders()};
    const url = this.baseUrl + '/api/v2/client/trilha/detalhe/' + id;
    return this.http.get(url, header);
  }

  public getContent(id: number) {
    const header = {headers: this.getHeaders()};
    const url = this.baseUrl + '/api/v2/admin/trail/content/' + id;
    return this.http.get(url, header);
  }

  public setContent(contentId: number, content: any) {
    const url = this.baseUrl + '/api/v2/admin/trail/content/' + contentId + '/update';
    const header = {headers: this.getHeaders()};
    return this.http.put(url, {value: content}, header);
  }

  private getHeaders(): HttpHeaders {
    const token = sessionStorage.getItem('token');

    const headerOptions = {
      'Authorization': token,
      'Accept': 'application/json'
    };

    return new HttpHeaders(headerOptions);
  }

  orderStages(order: string): Observable<any> {
    const url = this.baseUrl + '/api/v2/client/trilha/order/stages';
    const header = {headers: this.getHeaders()};
    return this.http.post(url, {order: order}, header);
  }

  orderSubStages(order: string): Observable<any> {
    const url = this.baseUrl + '/api/v2/client/trilha/order/sub-stages';
    const header = {headers: this.getHeaders()};
    return this.http.post(url, {order: order}, header);
  }

  getTrail(): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    };
    const header = new HttpHeaders(headerOptions);
    return this.http.get(this.baseUrl + '/api/v2/client/trilha', {headers: header});
  }

  getTrailUser(): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    };
    const header = new HttpHeaders(headerOptions);
    return this.http.get(this.baseUrl + '/api/v2/client/trilha/autenticado', {headers: header});
  }

  getFavorite(): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    };
    const header = new HttpHeaders(headerOptions);
    return this.http.get(this.baseUrl + '/api/v2/client/favorito', {headers: header});
  }

  postFavorite(id:any): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      'Authorization': token,
      'Accept': 'application/json'
    };
    const header = new HttpHeaders(headerOptions);
    return this.http.post(this.baseUrl + '/api/v2/client/favorito', {'trilha_id': id}, {headers: header});
  }

  getTrailByType(type): Observable<any> {
    const token = sessionStorage.getItem('token');
    const headerOptions = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    };
    if (token) headerOptions['Authorization'] = token;
    const header = new HttpHeaders(headerOptions);
    return this.http.get(this.baseUrl + '/api/v2/client/trilha/geral/tipo?type=' + type, {headers: this.getHeaders()});
  }

  getChallengeModules(id): Observable<any> {
    return this.http.get(this.baseUrl + '/api/v2/client/trilha/avatar/desafio/modulos/' + id,  {headers: this.getHeaders()});
  }

  aceptChallengeModules(id: any): Observable<any> {
    return this.http.post(this.baseUrl + '/api/v2/client/trilha/avatar/desafio/modulos/' + id , {},  {headers: this.getHeaders()});
  }

  challengeCloseModal(id: any): Observable<any> {
    return this.http.post(this.baseUrl + '/api/v2/client/trilha/avatar/modal/' + id , {},  {headers: this.getHeaders()});
  }

}
