import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map'
import 'rxjs/add/operator/first';

import { User } from '../models/user';
import { LibraryHelper } from '../@core/helpers/library.helper';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {

  constructor(public http: HttpClient) {
  }

  login(user: User): any {
    const body = {
     // client_id: 'asta',
     // client_secret: '123456',
     // grant_type: 'password',
      email: user.username,
      password: user.password
    };
    return this.http.post(environment.baseUrl + '/api/oauth/access_token', body);
  }

  register(user: User, clientId: number): Observable<any> {
    const body = {
      client_id: clientId,
      user
    };
    const url = environment.baseUrl + '/api/v2/client/register';
    return this.http.post(url, body);
  }

  isAuthenticated(): boolean {
    const token: string = sessionStorage.getItem('token');
    if (!token) {
      return false;
    }
    return true;
  }

  setSessionToken(data): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const token = 'Bearer' + ' ' + data.token;
      sessionStorage.setItem('token', token);
      this.getUserLogged().subscribe((res:any) => {
        this.verifica().subscribe(verifica => {
          console.log("verifica", verifica);
          sessionStorage.setItem('lab_verifica', (verifica.success) ? '1' : '0');
          if (res.success === false) {
            resolve(false);
          } else {
            sessionStorage.setItem('loggedUser', JSON.stringify(res));
            resolve(true);
          }
        });
      }, err => reject(err));
    });
  }

  setSessionTokenLogin(data): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const token = 'Bearer' + ' ' + data.access_token;
      sessionStorage.setItem('token', token);
      this.getUserLogged().subscribe((res:any) => {
        this.verifica().subscribe(verifica => {
          console.log("verifica", verifica);
          sessionStorage.setItem('lab_verifica', (verifica.success) ? '1' : '0');
          if (res.success === false) {
            resolve(false);
          } else {
            sessionStorage.setItem('loggedUser', JSON.stringify(res));
            resolve(true);
          }
        });
      }, err => reject(err));
    });
  }
  
  socialAuth(body) {
    
    console.log('body')
    console.log(body)
    console.log('body')
    
    return this.http.post(environment.baseUrl + '/api/social_auth', body);
  }

  verifica(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/verifica', LibraryHelper.getHeaders());
  }

  complement(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/user/form', LibraryHelper.getHeaders());
  }

  complementSave(id: number, data: any): Observable<any> {
    return this.http.put(environment.baseUrl + '/api/v2/perfil/sobre/' + id, data, LibraryHelper.getHeaders());
  }

  getUserLogged(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/user/authenticated', LibraryHelper.getHeaders());
  }

  userInfo(id): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/feed/userInfo/' + id, LibraryHelper.getHeaders());
  }

  verifyClient(clientId: number): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/client/verifyclient', {client_id: clientId}, LibraryHelper.getHeaders());
  }

  dismissStorage(): void {
    sessionStorage.clear();
  }

  syncActiveClient(client: string): void {
    sessionStorage.setItem('client', client);
  }

  getActiveClient(): any {
    return +sessionStorage.getItem('client');
  }

  rejectManifest(rejectText) {
    let clientId = this.getActiveClient();
    return this.http.post(environment.baseUrl + '/api/v2/client/manifest/reject', {client_id: clientId, message: rejectText}, LibraryHelper.getHeaders());
  }


}
