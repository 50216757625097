import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrailService } from 'app/services/trail.service';

@Component({
    selector: 'app-plan',
    templateUrl: './plan.component.html',
    styleUrls: ['../../app.component.css'],
    providers: [ TrailService ]
})
export class PlanComponent {

    public trail;
    public title = "Planos";
    public description = "Escolha a unidade que fica mais próximo de você."

    constructor(
        public trailService: TrailService,
        public route: ActivatedRoute
    ) {
        this.route.params.subscribe((params) => {
            if (params.trail_id) {
                this.trailService.getDetail(params.trail_id).subscribe((response:any) => {
                    this.trail = response.data;
                    this.title = this.trail[0].titulo;
                    this.description = this.trail[0].descricao;
                });
            }
        });

    }    
  
}
