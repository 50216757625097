import { Component, OnInit } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-menu-initiatives',
  templateUrl: './menu-initiatives.component.html',
  styleUrls: ['../../app.component.css', './menu-initiatives.component.css']
})

export class MenuInitiativesComponent implements OnInit {
	ngOnInit() {
	$(function () {
  $('[data-toggle="tooltip"]').tooltip()
})
}
}
