
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { SharedModule } from '../../shared/shared.module';

import { SellPlanComponent } from './sell-plan/sell-plan.component';
import { PlanComponent } from './plan.component';



@NgModule({
    imports: [
        CommonModule,
        RouterModule,//.forChild(DevelopmentRoutes),
        FormsModule,
        SharedModule
    ],
    declarations: [
        SellPlanComponent,
        PlanComponent
    ],
    providers: [

    ],
    exports: [
        SellPlanComponent,
        PlanComponent
    ]
})

export class PlanModule {}
