import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalUploadPluginComponent } from './modal-upload/modal-upload.component';

@Component({
  selector: 'app-upload-plugin',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})

export class UploadPluginComponent {

    @Input() horizontal;
    @Input() aspectRatio;
    @Input() resizeToWidth;
    @Input() type;
    @Input() multiple;
    @Input() onlyType;
    @Output() AddPhoto: EventEmitter<any> = new EventEmitter<any>();
    @Output() AddArchive: EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public dialog: MatDialog
    ) { }

    openDialog(): void {
        const dialogRef = this.dialog.open(ModalUploadPluginComponent, {
            width: '90%',
            maxWidth: '90%',
            height: '90vh',
            disableClose: true,
            data: {
                type: this.type,
                resizeToWidth: this.resizeToWidth,
                aspectRatio: this.aspectRatio,
                horizontal: this.horizontal,
                multiple: this.multiple,                  
                onlyType: this.onlyType
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                if (this.type === 'image') {
                    this.AddPhoto.emit(result);
                } else {
                    this.AddArchive.emit(result);
                }
            }
        });
    }

}
