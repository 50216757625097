import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { environment } from '../../environments/environment';


@Injectable()
export class SmartfitPropostaService {
  constructor(public http: HttpClient) { }

    getHeaders(): {headers: HttpHeaders} {
      const token = sessionStorage.getItem('token');
      const headerOptions = {
        'Authorization': token,
        'Accept': 'application/json'
      };
      return {headers: new HttpHeaders(headerOptions)};
    }

  public insertProject(body): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/projetos/list', body, this.getHeaders());
  }

  getProjects(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/projetos/list', this.getHeaders());
  }

  getProjectsAdmin(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/projetos/list', this.getHeaders());
  }

  getProjectsId(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + `/api/v2/projetos/list/${id}`, this.getHeaders());
  }

  getProjectsAdminId(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + `/api/v2/admin/projetos/list/${id}`, this.getHeaders());
  }

  getMyData(): Observable<any>  {
     return this.http.get(environment.baseUrl + '/api/v2/projetos/dados', this.getHeaders()); 
  }
 
  public updateMyData(body): Observable<any> {
    return this.http.put(environment.baseUrl + '/api/v2/projetos/dados', body, this.getHeaders());
  }

  public parecer(body, id): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/projetos/parecer/'+id, body, this.getHeaders());
  }

  public encaminhamento(body, id): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/projetos/encaminhamento/'+id, body, this.getHeaders());
  }

  public devolutiva(body, id): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/projetos/devolutiva/'+id, body, this.getHeaders());
  }

}