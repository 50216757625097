import { Component } from '@angular/core';
import { PlanService } from 'app/services/plan.service';
import { Router, ActivatedRoute } from '@angular/router';

declare var swal:any;

@Component({
    selector: 'app-sell-plan',
    templateUrl: './sell-plan.component.html',
    styleUrls: ['../../../app.component.css'],
    providers: [ PlanService ]
})
export class SellPlanComponent {

    public plans;
    public logado = false;

    constructor(
        public planService: PlanService,
        public route: ActivatedRoute,
        public router: Router,
    ) {
        this.route.params.subscribe((params) => {
            console.log("SellPlan",params);
            if (params.trail_id) {
                this.planService.getResources({router:"trail/" + params.trail_id}).subscribe((response:any) => {
                    this.plans = response.data;
                });
            }
            else {
                this.planService.getResources().subscribe((response:any) => {
                    this.plans = response.data;
                });                
            }

        });

    }

    ngOnInit() {
        let loggedUser = sessionStorage.getItem("loggedUser");
        this.logado = (loggedUser != null);
    }

    public buyPlan(plan_id) {
        this.planService.userInPlan(plan_id).subscribe((response:any) => {
            console.log(response);
            if (response.user_id) {
                swal("Inscrito","Você já está inscrito neste Plano!","success");
            }
            else {
                this.router.navigate(['/buy/plan', plan_id]);
            }
        });
    }
  
}
