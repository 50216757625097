import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';

import {environment} from '../../../environments/environment';
import {ModelsInterface} from '../interfaces/models.interface';
import {LibraryHelper} from '../helpers/library.helper';

@Injectable()
export class Trail implements ModelsInterface {
  public id: string;
  public name: string;
  public date: string;
  public titulo?: string;
  public descricao?: string;
  public arquivo?: string;
  public pasta_id?: number;
  public role: string;
  public tag: string;

  constructor(private http: HttpClient) {
  }

  populate(data: any): Trail {
    const trail = new Trail(this.http);
    trail.id = data.id;
    trail.name = data.name;
    trail.date = data.date;
    trail.role = data.role;
    trail.tag = data.tag;
    return trail;
  }

  findHome(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/home', LibraryHelper.getHeaders());
  }

  findAll(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/folder', LibraryHelper.getHeaders());
  }

  findAllClient(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha', LibraryHelper.getHeaders());
  }

  findAllAvatar(): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/avatar', LibraryHelper.getHeaders());
  }

  findAvatar(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/avatar/' + id , LibraryHelper.getHeaders());
  }

  findAvatarScore(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/avatar/pontos/gerais/' + id , LibraryHelper.getHeaders());
  }

  findTrainingScore(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/avatar/pontos/modulos/gerais/' + id , LibraryHelper.getHeaders());
  }

  avatarUnlock(id: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/client/trilha/avatar/unlock/' + id , {},  LibraryHelper.getHeaders());
  }

  aceptChallenge(id: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/client/trilha/avatar/desafio/' + id , {},  LibraryHelper.getHeaders());
  }

  challengeCloseModal(id: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/client/trilha/avatar/modal/' + id , {},  LibraryHelper.getHeaders());
  }

  getChallenge(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/avatar/desafio/' + id,  LibraryHelper.getHeaders());
  }

  getChallengeModules(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/avatar/desafio/modulos/' + id,  LibraryHelper.getHeaders());
  }

  aceptChallengeModules(id: any): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/client/trilha/avatar/desafio/modulos/' + id , {},  LibraryHelper.getHeaders());
  }

  getVideo(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/videos/' + id,  LibraryHelper.getHeaders());
  }

  getAllVideo(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/videos/' + id,  LibraryHelper.getHeaders());
  }

  findLevels(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/avatar/niveis/' + id , LibraryHelper.getHeaders());
  }

  searchLevels(id: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/client/trilha/avatar/busca/' + id , LibraryHelper.getHeaders());
  }

  findOneBy(data: any): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/item/' + data.id + '/edit', LibraryHelper.getHeaders());
  }

  findBy(id: number): Observable<any> {
    return this.http.get(environment.baseUrl + '/api/v2/admin/trail/item/' + id + '/edit', LibraryHelper.getHeaders());
  }

  filterBy(data: any[]): Observable<any> {
    return this.http.get(environment.baseUrl
      + '/api/v2/admin/trail/config/filter/group?nome=&cpf=&sexo=&localidade=&uf=&unidades=&cargos=&unidade='
      + data[1] + '&cargo=' + data[0], LibraryHelper.getHeaders());
  }

  update(trail: Trail): Observable<any> {
    return this.http.put(environment.baseUrl + '/api/v2/admin/trail/item/' + trail.id + '/update', trail, LibraryHelper.getHeaders());
  }

  persist(trail: Trail): Observable<any> {
    const body = {
      nome_pasta: trail.name,
      role: trail.role
    };

    if (trail.id) {
      return this.http.put(environment.baseUrl + '/api/v2/admin/trail/folder/' + trail.id + '/update', body, LibraryHelper.getHeaders());
    } else {
      return this.http.post(environment.baseUrl + '/api/v2/admin/trail/folder/post', body, LibraryHelper.getHeaders());
    }
  }

  saveData(trail: Trail): Observable<any> {
    return this.http.post(environment.baseUrl + '/api/v2/admin/trail/item/post', trail, LibraryHelper.getHeaders());
  }

  delete(id: number): Observable<any> {
    const url = environment.baseUrl + '/api/v2/admin/trail/folder/' + id + '/delete';
    return this.http.delete(url, LibraryHelper.getHeaders());
  }

  setStatus(data: {status: number}, id: number): Observable<any> {
    const url = environment.baseUrl + '/api/v2/client/trilha/published/' + id;
    return this.http.post(url, data, LibraryHelper.getHeaders());
  }

}
