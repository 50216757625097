import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Global } from 'asap-crud';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['../../app.component.css', './footer.component.css'],
})

export class FooterComponent {

    public info;

    constructor(
        public router: Router,
        public global: Global
    ) { }

    ngOnInit() {
       this.info = this.global.loggedUser();
    }

    public  logoff() {
        sessionStorage.removeItem("loggedUser");
        sessionStorage.removeItem("token");
        this.router.navigate(["/register"]);     
    }

}
