import {Component, OnInit, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {loginSocial} from '../../@core/services/loginSocial.service';
import {loginSocialModel} from '../../@core/models/loginSocial.model';
import {User} from '../../models/user';
import { AuthService as SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';

declare const $: any;
declare var swal:any;

@Component({
  selector: 'app-auth-navbar',
  templateUrl: './auth-navbar.component.html',
  styleUrls: ['../../app.component.css', '../navbar/navbar.component.css'],
  providers: [AuthService,loginSocial]
})
export class AuthNavbarComponent  {

  public navStyle
  public logo

  public social: any;
  public user: User;
  public form: FormGroup;
  public isLoading: boolean;
  public socialDate: any;

  constructor(
    private socialAuthService: SocialAuthService,
    public authService: AuthService,
    public router: Router,
    public fb: FormBuilder,
    private _login: loginSocial) {
    router.events.subscribe((val) => {

     if (val['url'] == '/register') {
       this.navStyle = 'nav-login'
       this.logo = 'logo.png'
     }

     else {
       this.navStyle = 'nav-login'
       this.logo = 'logo.png'
     }

    });
    this.buildForm();
    this.isLoading = false;
  }

  ngOnInit(): void {
    // iniciar com o client_id direto no component;
  }

  buildForm(): void {
    this.form = this.fb.group({
      username: ['', Validators.minLength(2)],
      password: ['', Validators.minLength(2)]
    });
  }

  onSubmit(): void {
    this.isLoading = true;
    this.user = this.prepareLogin();
    this.authService.login(this.user)
    .subscribe((data:any) => {
      this.authService.setSessionToken(data)
      .then((res:any) => {
        if (res) {
          this.redirect();  
          this.isLoading = true;              
        } else {
          this.isLoading = false;
                this.router.navigate(['/erros/403']);
              }
            })
      .catch(err => {
        this.isLoading = false;
      });
    },
    err => {
      this.isLoading = false;
      this.errorNotificate('Nome de usuário ou senha incorretos.');
    });
  }

  prepareLogin(): User {
    const formModel = this.form.value;
    const userModel = new User();
    userModel.populate(formModel);
    return userModel;
  }

  errorNotificate(msg: string): any {
    swal({
      title: 'Erro',
      text: msg,
      html: '<p><a href="/recuperacao">Esqueceu sua senha?</a></p>',
      type: 'warning',
      confirmButtonClass: 'btn btn-success',
    });
  }

  getRouter(): Router {
    return this.router;
  }

  public facebook() {
    this.isLoading = true;
    let socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
    this.socialAuthService.signIn(socialPlatformProvider).then(
      (userData) => {
        this.socialDate = {
          provider_user_id: userData.id,
          provider: 'facebook',
          picture: userData.photoUrl,
          name: userData.name,
          email: userData.email,
          client_id: 3
        }
        this.authService.socialAuth(this.socialDate).subscribe((data:any) => {
          this.authService.setSessionTokenLogin(data).then((res:any) => {
            if (res) {
              this.isLoading = false;
              this.redirect();
            }
          })
        }, err => {
          swal('Houve um erro ao se cadastrar, tente novamente mais tarde');
          this.isLoading = false;
        })
      }
    );
  }

  redirect(): void {
    let loggedUser = JSON.parse(sessionStorage.getItem('loggedUser'));
    sessionStorage.setItem('fireUser', loggedUser.data.id);
    sessionStorage.setItem('platform', 'lab60');

    if(sessionStorage.getItem('lab_verifica') === '0') {
      this.router.navigate(['/complement-register']);
    } else {
      this.getRouter().navigate(['/home']);
    }
  }

  getOVerlay(event): void {
    this.isLoading = event;
  }
}
