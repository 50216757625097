import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { EventService } from 'app/services/event.service';
import { IniciativaService } from 'app/services/iniciativa.service';

declare const $: any;

@Component({
    selector: 'app-menu-home',
    templateUrl: './menu-home.component.html',
    styleUrls: ['../../app.component.css', './menu-home.component.css'],
    providers: [ EventService, IniciativaService ]
})

export class MenuHomeComponent implements OnInit {

    @Input() forum;
    @Input() loggedUser;

    public apoios = null;
    public todos = false;
    public events = null;
    public todosEvents = false;

    constructor(
        public iniciativaService: IniciativaService,
        public eventService: EventService
    ) {

    }

    ngOnInit() {
        moment.locale('pt-br');
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        });
        
        this.eventService.getResources().subscribe((response:any) => {
            this.events = response;
        });

        this.iniciativaService.semApoio().subscribe((response:any) => {
            this.apoios = response;
        });
    }

    public homeApoios(quantidade) {
        if (this.todos) {
            return this.apoios;
        }
        return (this.apoios)?this.apoios.slice(0,quantidade):null;
    }

    public homeEvents(quantidade) {
        if (this.todosEvents) {
            return this.events;
        }
        return (this.events)?this.events.slice(0,quantidade):null;
    }

}
