import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '../loja/base.service';
import { environment } from 'environments/environment';

@Injectable()
export class TutorService extends BaseService {

    constructor(protected http: HttpClient) {
        super(http, 'api', 'v2')
    }

    public profile() {
        return this.getResources({router:'client/home/verify/profile'});
    }

    public tutor() {
        return this.getResources({router:'client/painel/tutor'});
    }

    public classes(trail_id) {
        return this.getResources({router:'client/painel/class/' + trail_id});
    }

    public student(class_id) {
        return this.getResources({router:'client/painel/student/' + class_id});
    }

    public users(search) {
        let data = {
            search: search
        }
        return this.createResource(data,{router:'admin/trail/user/search'});
    }

    public clearData(item_id,user_id) {
        return this.getResources({router:'admin/trail/config/students/' + item_id + '/' + user_id});
    }

    public saveClass(class_id,data) {
        return this.createResource(data, {router:'admin/trail/config/class/' + class_id});
    }

    public addStudent(class_id,user_id) {
        let data = {
            "user_id":user_id
        }
        return this.createResource(data, {router:'admin/trail/config/class/user/' + class_id});
    }

    public removeStudent(id) {
        return this.http.delete(environment.apiUrl + '/api/v2/admin/trail/config/class/user/' + id,this.options);
    }

    public studentExtraData(trail_id,student) {
        let result:any = this.getResources({router:'client/trilha/performance/trail/' + trail_id + '/' + student.user_id});
        result.subscribe((response:any) => {
            student.acerto = response.data.CorrectAnswer;
            student.erro = response.data.WrongAnswer;
            student.questions = response.data.sequence;
        });
        return result;
    }

    public videoTime(trail_id,user_id) {
        return this.getResources({router:'client/trilha/performance/trail/video/' + trail_id + '/' + user_id});
    } 

}