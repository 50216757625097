
import { Routes } from '@angular/router';

import { AuthComponent } from './auth/auth.component';
import { SettingsComponent } from './settings/settings.component';
import { PublicComponent } from './public/public.component';
import { RoleGuardService } from './services/role-guard.service';
import { PlanComponent } from './public/plans/plan.component';
import { TutorPanelComponent } from './public/tutor-panel/tutor-panel.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'register',
        loadChildren: () => import('./auth/register/register.module').then(m => m.RegisterModule)
      },
      {
        path: 'recovery',
        loadChildren: () => import('./auth/recovery/recovery.module').then(m => m.RecoveryModule)
      }
    ]
  },
  {
    path: '',
    component: SettingsComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./settings/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'my-supports',
        loadChildren: () => import('./settings/my-supports/my-supports.module').then(m => m.MySupportsModule)
      },
      {
        path: 'talk',
        loadChildren: () => import('./settings/talk/talk.module').then(m => m.TalkModule)
      },
      {
        path: 'settings-initiative',
        loadChildren: () => import('./settings/settings-initiative/settings-initiative.module').then(m => m.SettingsInitiativeModule)
      },
      {
        path: 'settings-initiative/:id',
        loadChildren: () => import('./settings/settings-initiative/settings-initiative.module').then(m => m.SettingsInitiativeModule)
      }
    ]
  },
  {
    path: '',
    component: PublicComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./public/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'plans',
        component: PlanComponent,
      },
      {
        path: 'plans/:trail_id',
        component: PlanComponent,
      },
      {
        path: 'initiatives',
        loadChildren: () => import('./public/initiatives/initiatives.module').then(m => m.InitiativesModule),
      },
      {
        path: 'development',
        loadChildren: () => import('./public/development/development.module').then(m => m.DevelopmentModule)
      },
      {
        path: 'buy',
        loadChildren: () => import('./public/order-b2c/order-b2c.module').then(m => m.OrderB2cModule)
      },
      {
        path: 'forum',
        loadChildren: () => import('./public/forum/forum.module').then(m => m.ForumModule)
      },
      {
        path: 'forum/:trail_id',
        loadChildren: () => import('./public/forum/forum.module').then(m => m.ForumModule)
      },
      {
        path: 'my-page',
        loadChildren: () => import('./public/my-page/my-page.module').then(m => m.MyPageModule)
      },
      {
        path: 'understand-movement',
        loadChildren: () => import('./public/understand-movement/understand-movement.module').then(m => m.UnderstandMovementModule)
      },
      {
        path: 'complement-register',
        loadChildren: () => import('./public/complement-register/complement-register.module').then(m => m.ComplementModule)
      },
      {
        path: '',
        component: TutorPanelComponent,
        children: [
          {
            path: '',
            loadChildren: () => import('./public/tutor-panel/courses/tutor-panel-courses.module').then(m => m.TutorPanelCoursesModule)
          },
          {
            path: 'tutor/classes/:id',
            loadChildren: () => import('./public/tutor-panel/classes/tutor-panel-classes.module').then(m => m.TutorPanelClassesModule)
          },
          {
            path: 'tutor/subscribers/:id',
            loadChildren: () => import('./public/tutor-panel/subscribers/tutor-panel-subscribers.module').then(m => m.TutorPanelSubscribersModule)
          }
        ]
      },
    ]
  }

];
