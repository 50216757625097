export const environment = {
  production: true,
  apiUrl: 'https://apiv3.plataformaasap.com.br',
  baseUrl: 'https://apiv3.plataformaasap.com.br',
  filestack: {
    key: 'AD5oDjsJaTJOzLe1Llj9mz'
  },
  pusher: {
    key: 'eaf41725f3b027147953',
  },
  firebase: {
    apiKey: "AIzaSyBo6x5MLjGIjrAgzzRbOX4rTmVqhpNqSmU",
    authDomain: "lab-60.firebaseapp.com",
    databaseURL: "https://lab-60.firebaseio.com",
    projectId: "lab-60",
    storageBucket: "lab-60.appspot.com",
    messagingSenderId: "915042152208"
  }  
};
