import { Component, OnInit } from '@angular/core';
import { Global } from 'asap-crud';

declare const $: any;

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.css']
})

export class SideMenuComponent implements OnInit {

  constructor(
    public global: Global
  ) {
    
  }

  ngOnInit() {

    $(document).ready(function () {

      $('#sidemenuCollapse').on('click', function () {
        $('.sidemenu, .content, .overlay').toggleClass('active');
      });

      $('.overlay').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

      $('.components').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

      $('.navbar-brand').on('click', function () {
        $('.sidemenu, .content, .overlay').removeClass('active');
      });

    });

  }

}
