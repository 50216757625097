import {Injectable} from '@angular/core';
import 'rxjs/add/observable/of';
import {Router, CanActivate} from '@angular/router';
import {AuthService} from './auth.service';

@Injectable()
export class RoleGuardService implements CanActivate {

    constructor(public auth: AuthService, public router: Router) {
    }

    canActivate(): boolean {
        // TODO Implementar a separação de roles
        return true;
    }

}
