import { Component } from '@angular/core';

@Component({
  selector: 'app-gain',
  templateUrl: './gain.component.html',
  styleUrls: ['../../app.component.css', './gain.component.css'],
})

export class GainComponent {

}
