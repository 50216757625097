import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-clientes-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.css']
})

export class SpinnerComponent { 

    @Input() radius;
    @Input() message = "Carregando...";

}
